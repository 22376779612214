// @ts-strict-ignore
import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { catchError, take } from 'rxjs/operators';

import {
  HelpRequestOptions,
  HelpRequestService,
} from '@app/modules/help-request/help-request.service';
import { DropdownItem, OMG_DIALOG_DATA } from '@app/shared';
import { DialogRef } from '@app/shared/components/dialog';
import { get } from '@app/utils';

import { HelpRequestForm } from './../help-request-form';
import { HelpRequestFormService } from './../help-request-form.service';
import { HelpRequestDetails } from './../help-request.service';

@Component({
  selector: 'omg-help-request-confirmation-modal',
  templateUrl: './help-request-confirmation-modal.component.html',
  styleUrls: ['./help-request-confirmation-modal.component.scss'],
})
export class HelpRequestConfirmationModalComponent
  implements OnInit, OnDestroy {
  helpOutWikiLink =
    'https://sites.google.com/onemedical.com/1life-remote-workflow/remote-care/helpout';
  isLinkedToNote: boolean;
  hasRequiredNoteDetails: boolean;
  helpFlowTypes$: Observable<DropdownItem[]>;
  usStates: DropdownItem[];
  saving = false;
  form: HelpRequestForm;

  private unsubscribe$ = new Subject<void>();

  constructor(
    public dialogRef: DialogRef<HelpRequestConfirmationModalComponent, boolean>,
    @Inject(OMG_DIALOG_DATA) public requestOptions: HelpRequestOptions,
    private helpRequestService: HelpRequestService,
    private helpRequestFormService: HelpRequestFormService,
  ) {}

  ngOnInit(): void {
    this.form = this.helpRequestFormService.buildForm(this.unsubscribe$);
    this.isLinkedToNote = !!this.requestOptions.linkedNoteId;

    this.hasRequiredNoteDetails =
      this.isLinkedToNote && !!get('summary.subject', this.requestOptions);
  }

  getVMTHelp() {
    const helpRequestDetails: HelpRequestDetails = {
      options: this.requestOptions,
      ...this.form.value,
    };
    this.saving = true;
    this.helpRequestService
      .getHelp(helpRequestDetails)
      .pipe(take(1))
      .subscribe(() => {
        this.saving = false;
        this.close();
      });
  }

  close() {
    this.dialogRef.close(false);
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
